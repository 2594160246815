import { useContext, useEffect } from "react";
import AppContext from "../contexts/appContext/appContext";

export function usePaidUser() {
  const { globalData = {} } = useContext(AppContext) || {};
  const { profile: globalProfileData = {} } = globalData;
  const profile = globalProfileData?.data || {};

  const isPaidUser = () => {
    return Boolean(profile?.plan && profile.plan !== "Free");
  };

  const isLifetimeUser = () => {
    return Boolean(profile?.plan && profile.isLifetimePlan);
  };

  const isSelectedPlan = (planName) => {
    return Boolean(profile?.plan && profile.plan === planName);
  };

  return {
    isPaidUser,
    isLifetimeUser,
    isSelectedPlan,
    profile,
    isLoading: globalProfileData?.state === "init",
  };
}
