import React, { useState, useEffect } from "react";
import { Card } from "react-bootstrap";

const AdWidget = ({ onComplete, adContent, duration = 10 }) => {
  const [timeLeft, setTimeLeft] = useState(duration * 1000); // Convert to milliseconds

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeLeft((prev) => {
        if (prev <= 50) {
          // Small threshold to ensure we don't miss the completion
          clearInterval(interval);
          setTimeout(onComplete, 0);
          return 0;
        }
        return prev - 50; // Update every 50ms
      });
    }, 50);

    return () => clearInterval(interval);
  }, [onComplete]);

  return (
    <div className="tw-mx-auto tw-animate-fade-in tw-bg-white tw-px-6 tw-rounded-lg tw-w-full lg:tw-max-w-4xl tw-border-2 tw-border-solid tw-border-gray-300">
      <div className="tw-overflow-hidden tw-relative">
        <div className="tw-py-1 tw-mt-2">
          {adContent || (
            <div className="tw-space-y-2 sm:tw-space-y-4">
              <h3 className="tw-text-xl tw-font-bold tw-text-center sm:tw-text-2xl">Special Offer!</h3>
              <p className="tw-text-sm tw-text-center tw-text-gray-600 sm:tw-text-base">
                This is a sample advertisement content.
              </p>
              <div className="tw-flex tw-justify-center tw-items-center tw-w-full tw-h-32 tw-text-lg tw-text-white tw-bg-gradient-to-r tw-from-blue-400 tw-to-purple-500 tw-rounded-lg sm:tw-h-40 sm:tw-text-xl">
                Ad Space
              </div>
            </div>
          )}
        </div>
        <div className="tw-py-1 tw-rounded-t-lg tw-bg-white tw-px-2">
          <div className="tw-flex tw-items-center tw-mb-1">
            <span className="tw-text-[8px] tw-text-gray-800 tw-mx-auto md:tw-text-xs">
              Ad ends in: {Math.ceil(timeLeft / 1000)} seconds
            </span>
          </div>
          <div
            className="tw-bg-[#ff4a00] tw-transition-all tw-h-0.5 tw-rounded tw-ease-linear tw-duration-50"
            style={{
              width: `${(timeLeft / (duration * 1000)) * 100}%`,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default AdWidget;
