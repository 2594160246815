import { useState, useEffect } from "react";
import dynamic from "next/dynamic";
import CallToAction from "../common/call-to-action";
import { LoaderCircle } from "lucide-react";
import AdWidget from "../layouts/AdsWidget";

const LoadingWidget = () => (
  <div className="tw-flex tw-items-center tw-justify-center">
    <LoaderCircle className="tw-w-5 tw-h-5 tw-animate-spin tw-text-gray-500 tw-mr-1 !tw-stroke-2" />
    <p className="tw-text-gray-500 !tw-m-0">Loading...</p>
  </div>
);

// Dynamically import ad components
const JenniAiAd = dynamic(() => import("./jenni-ai").then((mod) => mod.JenniAiAd), {
  loading: () => <LoadingWidget />,
  ssr: false,
});

const HeadshotsAd = dynamic(() => import("./headshots").then((mod) => mod.HeadshotsAd), {
  loading: () => <LoadingWidget />,
  ssr: false,
});

function AdsBanner() {
  const [showAd, setShowAd] = useState(true);
  const [selectedAd, setSelectedAd] = useState(null);

  useEffect(() => {
    // Array of ad components
    const ads = [<JenniAiAd key="jenni" />, <HeadshotsAd key="headshots" />, <HeadshotsAd key="headshots" />];

    // Get timestamp in seconds
    const timestamp = Math.floor(Date.now() / 1000);
    // Switch ad every 30 seconds based on timestamp
    const adIndex = Math.floor(timestamp / 30) % ads.length;

    setSelectedAd(ads[adIndex]);
  }, []);

  function handleAdComplete() {
    setShowAd(false);
  }

  return (
    <div className="d-flex align-items-center">
      <div className="container" style={{ width: "100%" }}>
        <div className="text-center d-flex flex-column">
          <div className="container tw-my-12">
            {showAd ? (
              selectedAd && <AdWidget duration={120} onComplete={handleAdComplete} adContent={selectedAd} />
            ) : (
              <CallToAction origin="widget-disabled" showSignup={false} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdsBanner;
