import { Check } from "lucide-react";

function HabitTracker({ showSignup = true, showExplore = true, origin = "internal" }) {
  return (
    <div className="tw-mt-10 tw-mx-auto tw-bg-gradient-to-r tw-from-[#290b61] tw-via-50% tw-to-[#120231] tw-px-6 tw-rounded-lg tw-w-full lg:tw-max-w-4xl">
      <div className="tw-flex tw-py-4 tw-bg-opacity-5" style={{ backgroundImage: "url('/glamorous.svg')" }}>
        <div className="tw-hidden md:tw-grid tw-w-40 tw-mx-auto tw-text-center tw-self-center tw-text-8xl">🎯</div>
        <div className="tw-grow lg:tw-ml-11 tw-items-start">
          <h2 className="tw-text-xs tw-font-bold tw-tracking-tight tw-text-white md:tw-text-2xl sm:tw-text-lg">
            Track your habits and goals in Notion.
          </h2>
          <ul className="tw-hidden tw-text-small tw-text-start tw-text-white tw-px-0 tw-opacity-80 tw-mt-5 tw-text-sm md:tw-block md:tw-text-base">
            <li className="tw-mb-1 list-group-item">
              <Check size={18} className="text-white" /> Tailor your habits to perfectly align with your goals &
              priorities.
            </li>
            <li className="tw-mb-1 list-group-item">
              <Check size={18} className="text-white" /> Easily visualize your progress with clear and intuitive
              tracking tools.
            </li>
            <li className="tw-mb-1 list-group-item">
              <Check size={18} className="text-white" /> Build routines that adapt seamlessly to your unique lifestyle.
            </li>
          </ul>
          <div className="tw-block lg:tw-hidden tw-w-auto tw-self-center md:tw-mt-8">
            {showExplore && (
              <a href={"/next/habit-tracker"} className="rounded shadow btn btn-primary !tw-text-xs md:tw-text-base">
                Start Tracking
              </a>
            )}
          </div>
        </div>
        <div className="tw-hidden lg:tw-block tw-w-auto tw-self-center tw-mt-8">
          {showExplore && (
            <a href={"/next/habit-tracker"} className="rounded shadow btn btn-primary">
              Start Tracking
            </a>
          )}
        </div>
      </div>
    </div>
  );
}

export default HabitTracker;
