export const WidgetsList = [
  {
    _id: "20241224",
    title: "Image Generator",
    slug: "image-generator",
    widgetType: "widget",
    type: "free",
    name: "image-generator",
    tagline: "Create stunning images directly in Notion – no extra tools required!",
    image: "/images/widgets/",
    thumbnail: "/images/widget-icons/image-gen.png",
    createdAt: "2024-12-19T08:50:41.925Z",
    updatedAt: "2024-12-19T08:50:41.925Z",
    isNext: true,
    category: "productivity",
    useForBusiness: false,
  },
  {
    _id: "20241229",
    title: "Progress Bar",
    slug: "progress-bar",
    widgetType: "widget",
    type: "free",
    name: "progress-bar",
    tagline: "Make your deadlines visible with our Progress Bar Widget! Easy to use and embed on your Notion page.",
    image: "/images/widgets/",
    thumbnail: "/images/widget-icons/progress-bar.png",
    createdAt: "2024-12-29T08:50:41.925Z",
    updatedAt: "2024-12-29T08:50:41.925Z",
    category: "counter",
    useForBusiness: false,
    isNew: true,
  },
  {
    _id: "20241017",
    title: "Time Tracker",
    slug: "time-tracker",
    widgetType: "widget",
    type: "free",
    name: "timetracker",
    tagline: "Track and visualize time spent on tasks and projects in Notion",
    image: "/images/widgets/",
    thumbnail: "/images/widget-icons/time-tracker.png",
    createdAt: "2024-10-17T08:50:41.925Z",
    updatedAt: "2024-10-17T08:50:41.925Z",
    isNew: true,
    category: "productivity",
    useForBusiness: true,
  },
  {
    _id: "202407072024",
    title: "Habit Counter",
    slug: "habit-counter",
    type: "free",
    name: "habit-counter",
    tagline:
      "Track your habits with our Habit Counter widget, helping you stay on top of your goals and build healthy habits.",
    image: "/images/widgets/",
    thumbnail: "/images/widget-icons/habit-counter.png",
    createdAt: "2024-07-07T08:50:41.925Z",
    updatedAt: "2024-07-07T08:50:41.925Z",
    category: "counter",
    useForBusiness: false,
  },
  {
    _id: "20230812",
    title: "To-Do List",
    slug: "todo",
    type: "free",
    name: "todo",
    tagline:
      "Effortlessly manage tasks in Notion using our To-Do List widget for streamlined task tracking and organization.",
    image: "/images/widgets/",
    thumbnail: "/images/widget-icons/to-do-list.png",
    createdAt: "2023-08-12T08:50:41.925Z",
    updatedAt: "2023-08-12T08:50:41.925Z",
    isNew: false,
    category: "productivity",
    useForBusiness: true,
  },
  {
    _id: "21231288826",
    title: "Notion Importer",
    slug: "notion-importer",
    type: "free",
    name: "notion-importer",
    tagline: "Import data from any source to Notion with a few clicks. It will sync data recurringly.",
    image: "/images/widgets/",
    thumbnail: "/images/widget-icons/notionbridge.png",
    createdAt: "2023-12-11T18:50:41.925Z",
    updatedAt: "2023-12-11T18:50:41.925Z",
    isPartner: true,
    category: "counter",
    useForBusiness: true,
  },
  {
    _id: "202401062024",
    title: "KPI Widget",
    slug: "kpi",
    type: "paid",
    name: "kpi-widget",
    tagline:
      "Track your key performance indicators with our KPI widget, helping you monitor and analyze your business metrics with ease.",
    image: "/images/widgets/",
    thumbnail: "/images/widget-icons/kpi-widget.png",
    createdAt: "2024-06-01T08:50:41.925Z",
    updatedAt: "2024-06-01T08:50:41.925Z",
    isNew: true,
    category: "counter",
    useForBusiness: true,
  },
  {
    _id: "20231212232",
    title: "Paypal Purchase Button",
    slug: "paypal-button",
    type: "free",
    name: "paypal-button",
    tagline:
      "Integrate a customizable PayPal button widget on your notion or website, allowing customers to click and pay for instant purchases.",
    image: "/images/widgets/",
    thumbnail: "/images/widget-icons/paypal-button.png",
    createdAt: "2023-12-22T08:50:41.925Z",
    updatedAt: "2023-12-22T08:50:41.925Z",
    isNew: true,
    category: "button",
    useForBusiness: true,
  },
  {
    _id: "20240330",
    title: "Counter Widget",
    slug: "counter",
    type: "free",
    name: "counter",
    tagline:
      "Create a simple counter widget. Customize the look and feel of the counter widget to match your website's design.",
    image: "/images/widgets/",
    thumbnail: "/images/widget-icons/counter-widget.png",
    createdAt: "2023-12-22T08:50:41.925Z",
    updatedAt: "2023-12-22T08:50:41.925Z",
    isNew: true,
    category: "counter",
    useForBusiness: false,
  },
  {
    _id: "20231210",
    title: "Button Widget",
    slug: "button",
    type: "free",
    name: "button",
    tagline:
      "Effortlessly enhance your Notion docs with our button widget. Click to send emails or link to sites, streamlining your workflow with ease.",
    image: "/images/widgets/",
    thumbnail: "/images/widget-icons/button.png",
    createdAt: "2023-12-10T08:50:41.925Z",
    updatedAt: "2023-12-10T08:50:41.925Z",
    isNew: false,
    category: "button",
    useForBusiness: true,
  },
  {
    _id: "20231222",
    title: "Water Tracker",
    slug: "water-tracker",
    type: "free",
    name: "water-tracker",
    tagline: "Track your daily water intake with our Water Tracker widget, helping you stay hydrated and healthy.",
    image: "/images/widgets/",
    thumbnail: "/images/widget-icons/water-tracker.png",
    createdAt: "2023-12-22T09:50:41.925Z",
    updatedAt: "2023-12-22T09:50:41.925Z",
    isPartner: true,
    category: "counter",
    useForBusiness: false,
  },
  {
    _id: "20241119",
    title: "AI Bot",
    slug: "ai-bot",
    widgetType: "widget",
    type: "free",
    name: "ai-bot",
    tagline: "Build AI bots with tailored input fields and outputs to match your specific needs.",
    image: "/images/widgets/",
    thumbnail: "/images/widget-icons/ai-bot-agent.png",
    createdAt: "2024-11-19T08:50:41.925Z",
    updatedAt: "2024-11-19T08:50:41.925Z",
    isNext: true,
    category: "productivity",
    useForBusiness: true,
  },
  {
    _id: "20231209",
    title: "Page View",
    slug: "page-view",
    type: "free",
    name: "page-view",
    tagline:
      "Enhance user experience with our Page View Widget, enabling users to easily track impressions and monitor page performance. Ideal for analytics.",
    image: "/images/widgets/",
    thumbnail: "/images/widget-icons/page-view.png",
    createdAt: "2023-12-09T08:50:41.925Z",
    updatedAt: "2023-12-09T08:50:41.925Z",
    isNew: true,
    category: "counter",
    useForBusiness: true,
  },
  {
    _id: "20231009",
    title: "Upvote Button",
    slug: "upvote-button",
    type: "free",
    name: "upvote-button",
    tagline:
      "Upvote button is a widget that allows visitors to either vote up or down on content within your Notion document or your website.",
    image: "/images/widgets/",
    thumbnail: "/images/widget-icons/upvote-button.png",
    createdAt: "2023-10-09T08:50:41.925Z",
    updatedAt: "2023-10-09T08:50:41.925Z",
    isNew: false,
    category: "button",
    useForBusiness: true,
  },
  {
    _id: "21231211",
    title: "Habit Tracker",
    slug: "habit-tracker",
    type: "free",
    name: "habit-tracker",
    tagline:
      "Track your habits with our Habit Tracker widget, helping you stay on top of your goals and build healthy habits.",
    image: "/images/widgets/",
    thumbnail: "/images/widget-icons/habit-tracker.png",
    createdAt: "2023-12-11T18:50:41.925Z",
    updatedAt: "2023-12-11T18:50:41.925Z",
    isPartner: true,
    category: "button",
    useForBusiness: false,
  },
  {
    _id: "20231211",
    title: "Weather Forecast",
    slug: "weather",
    type: "free",
    name: "weather",
    tagline:
      "Stay ahead with our weather widget: get real-time updates and 10-day forecasts, fully customizable with your choice of colors and icons.eather forecast for your Notion Dashboard.",
    image: "/images/widgets/",
    thumbnail: "/images/widget-icons/weather.png",
    createdAt: "2023-12-11T08:50:41.925Z",
    updatedAt: "2023-12-11T08:50:41.925Z",
    isNew: true,
    category: "weather",
    useForBusiness: false,
  },
  {
    _id: "20230907",
    title: "Weather Round",
    slug: "weather-round",
    type: "free",
    name: "weather-round",
    tagline: "Weather Round is a simple weather widget for your Notion Dashboard.",
    image: "/images/widgets/",
    thumbnail: "/images/widget-icons/weather-round.png",
    createdAt: "2023-09-06T08:50:41.925Z",
    updatedAt: "2023-09-06T08:50:41.925Z",
    isNew: false,
    category: "weather",
    useForBusiness: false,
  },
  {
    _id: "20230906",
    title: "Weather Square",
    slug: "weather-square",
    type: "free",
    name: "weather-square",
    tagline: "Weather Square is a simple weather widget for your Notion Dashboard.",
    image: "/images/widgets/",
    thumbnail: "/images/widget-icons/weather-square.png",
    createdAt: "2023-09-06T08:50:41.925Z",
    updatedAt: "2023-09-06T08:50:41.925Z",
    isNew: false,
    category: "weather",
    useForBusiness: false,
  },
  {
    _id: "20230820",
    title: "Like Button",
    slug: "like-button",
    type: "free",
    name: "like-button",
    tagline: "Allow your users to express their appreciation with this 'Like' button.",
    image: "/images/widgets/",
    thumbnail: "/images/widget-icons/like-button.png",
    createdAt: "2023-08-20T08:50:41.925Z",
    updatedAt: "2023-08-20T08:50:41.925Z",
    isNew: false,
    category: "button",
    useForBusiness: false,
  },
  {
    _id: "20230322",
    title: "Inspirational Quotes",
    slug: "inspirational-quotes",
    type: "free",
    name: "inspirational-quotes",
    tagline: "Minimalistic Quotes widget for your Notion.",
    image: "/images/widgets/",
    thumbnail: "/images/widgets/quotes/thumbnail.png",
    createdAt: "2023-01-01T08:50:41.925Z",
    updatedAt: "2023-01-01T08:50:41.925Z",
    isNew: false,
    category: "productivity",
    useForBusiness: false,
  },
  {
    _id: "20230723",
    title: "Charts for Notion",
    slug: "charts",
    type: "free",
    name: "charts",
    tagline:
      "Turn Notion databases into insightful charts. Create live-updating charts from your Notion databases in seconds with no code.",
    image: "/images/widgets/",
    thumbnail: "/images/widgets/charts/thumbnail.svg",
    createdAt: "2023-07-23T08:50:41.925Z",
    updatedAt: "2023-07-23T08:50:41.925Z",
    isPartner: true,
    category: "productivity",
    useForBusiness: true,
  },
  {
    _id: "20220122",
    title: "Word Clock",
    slug: "word-clock",
    type: "free",
    name: "word-clock",
    tagline: "Aesthetic Word Clock widget for your Notion Dashboard",
    image: "/images/widgets/",
    thumbnail: "/images/widgets/word-clock/home.png",
    createdAt: "2023-01-01T08:50:41.925Z",
    updatedAt: "2023-01-01T08:50:41.925Z",
    isNew: false,
    category: "counter",
    useForBusiness: false,
  },
  {
    _id: "20230122",
    title: "Life, Year, Month, Week and Day Progress",
    slug: "year-month-week-day-progress",
    type: "free",
    name: "year-month-week-day-progress",
    tagline: "Create beautiful progress bars for your life, year, month, week and day",
    image: "/images/widgets/",
    thumbnail: "/images/widgets/year-month-thumbnail.png",
    createdAt: "2023-01-01T08:50:41.925Z",
    updatedAt: "2023-01-01T08:50:41.925Z",
    isNew: false,
    category: "counter",
    useForBusiness: false,
  },
  {
    _id: "5d267e617cf22f67405e15ef",
    defaultOptions: [
      {
        title: "Default Theme",
        name: "default",
        data: {
          colors: {
            bodyBackground: "#ffffff",
            bodyColor: "#37352f",
            daysColor: "#37352f",
            hoursColor: "#37352f",
            minutesColor: "#37352f",
            secondsColor: "#37352f",
          },
          options: {
            showSeconds: true,
            showMinutes: true,
            showHours: true,
            showDays: true,
          },
          data: {
            time: "2024-01-01T18:50:57.200Z",
          },
        },
      },
      {
        title: "Dark Theme",
        name: "dark",
        data: {
          colors: {
            bodyBackground: "#2f3437",
            bodyColor: "#ffffff",
            daysColor: "#ffffff",
            hoursColor: "#ffffff",
            minutesColor: "#ffffff",
            secondsColor: "#ffffff",
          },
          options: {
            showSeconds: true,
            showMinutes: true,
            showHours: true,
            showDays: true,
          },
          data: {
            time: "2024-03-01T14:50:57.200Z",
          },
        },
      },
    ],
    title: "Simple Count Down Timer",
    slug: "simple-count-down",
    type: "free",
    name: "simplecountdown",
    tagline: "Simple Countdown to any Date and Time",
    description: "Looking forward for something?\nSee a simple countdown timer to any event in your life :)",
    image: "/images/widgets/count-down/banner-text.png",
    thumbnail: "/images/widgets/count-down/thumbnail-text.png",
    createdAt: "2020-08-02T08:50:41.925Z",
    updatedAt: "2020-08-02T08:50:41.925Z",
    category: "counter",
    useForBusiness: true,
    schema: {
      type: "object",
      properties: {
        title: {
          type: "string",
          title: "Title",
          default: "Friend BirthDay",
        },
        data: {
          type: "object",
          title: "Data",
          required: ["time"],
          properties: {
            time: {
              type: "string",
              title: "Date",
              format: "date-time",
            },
          },
        },
        options: {
          type: "object",
          title: "Options",
          properties: {
            showDays: {
              type: "boolean",
              title: "Show Days",
            },
            showHours: {
              type: "boolean",
              title: "Show Hours",
            },
            showMinutes: {
              type: "boolean",
              title: "Show Minutes",
            },
            showSeconds: {
              type: "boolean",
              title: "Show Seconds",
            },
          },
        },
        colors: {
          type: "object",
          title: "Colors",
          properties: {
            bodyBackground: {
              type: "string",
              title: "Widget Background",
              format: "color",
            },
            bodyColor: {
              type: "string",
              title: "Widget Color",
              format: "color",
            },
            daysColor: {
              type: "string",
              title: "Days Color",
              format: "color",
            },
            hoursColor: {
              type: "string",
              title: "Hours Color",
              format: "color",
            },
            minutesColor: {
              type: "string",
              title: "Minutes Color",
              format: "color",
            },
            secondsColor: {
              type: "string",
              title: "Seconds Color",
              format: "color",
            },
          },
        },
      },
      required: ["title"],
    },
    __v: 0,
  },
  {
    _id: "4d267e617cf22f67405e15ef",
    defaultOptions: [
      {
        title: "Default Theme",
        name: "default",
        data: {
          colors: {
            bodyBackground: "#ffffff",
            titleColor: "#37352f",
            timeColor: "#37352f",
            dateColor: "#37352f",
          },
          options: {
            showTitle: true,
            showDate: true,
            showSeconds: true,
            alignment: "left",
            "24hrsformat": true,
          },
          data: {
            timezone: "America/New_York",
          },
        },
      },
      {
        title: "Dark Theme",
        name: "dark",
        data: {
          colors: {
            bodyBackground: "#2f3437",
            titleColor: "#ffffff",
            timeColor: "#ffffff",
            dateColor: "#ffffff",
          },
          options: {
            showTitle: true,
            showDate: true,
            showSeconds: true,
            alignment: "center",
            "24hrsformat": false,
          },
          data: {
            timezone: "America/New_York",
          },
        },
      },
    ],
    title: "World Clock",
    slug: "world-clock",
    type: "free",
    name: "worldclock",
    tagline: "A simple clock for any timezone",
    description: "Shows the curren time of selected timezone. You can select from the 593 different timezone options.",
    image: "/images/widgets/world-clock/banner-text.png",
    thumbnail: "/images/widgets/world-clock/thumbnail-text.png",
    createdAt: "2020-08-02T08:50:41.925Z",
    updatedAt: "2020-08-02T08:50:41.925Z",
    category: "counter",
    useForBusiness: true,
    schema: {
      type: "object",
      properties: {
        title: {
          type: "string",
          title: "Title",
          default: "New York Time",
        },
        data: {
          type: "object",
          title: "Data",
          required: ["timezone"],
          properties: {
            timezone: {
              ref: "#/definitions/timeZone",
              title: "Time Zone",
            },
          },
        },
        options: {
          type: "object",
          title: "Options",
          properties: {
            showTitle: {
              type: "boolean",
              title: "Show Title",
            },
            showDate: {
              type: "boolean",
              title: "Show Date",
            },
            showSeconds: {
              type: "boolean",
              title: "Show Seconds",
            },
            "24hrsformat": {
              type: "boolean",
              title: "24hr Format",
            },
            alignment: {
              title: "Alignment",
              type: "string",
              enum: ["left", "right", "center"],
              enumNames: ["Left", "Right", "Center"],
              default: "left",
            },
          },
        },
        colors: {
          type: "object",
          title: "Colors",
          properties: {
            bodyBackground: {
              type: "string",
              title: "Widget Background",
              format: "color",
            },
            titleColor: {
              type: "string",
              title: "Title Color",
              format: "color",
            },
            timeColor: {
              type: "string",
              title: "Time Color",
              format: "color",
            },
            dateColor: {
              type: "string",
              title: "Date Color",
              format: "color",
            },
          },
        },
      },
      required: ["title"],
    },
    __v: 0,
  },
  {
    _id: "0513",
    title: "Pomodoro for Notion",
    slug: "pomodoro",
    widgetType: "app",
    type: "paid",
    name: "pomodoro",
    tagline: "Complete pomodoro solution for Notion. Including saving tasks to Notion DB.",
    thumbnail: "/images/apps/pomodoro/home.svg",
    isNew: true,
    category: "productivity",
    useForBusiness: true,
  },
  {
    _id: "21231288826",
    title: "Notion Importer",
    slug: "notion-importer",
    widgetType: "widget",
    type: "free",
    name: "notion-importer",
    tagline: "Import data from any source to Notion with a few clicks. It will sync data recurringly.",
    thumbnail: "/images/widget-icons/notionbridge.png",
    isPartner: true,
    category: "productivity",
    useForBusiness: false,
  },
  {
    _id: "12345",
    title: "Recurring Tasks",
    slug: "recurring-tasks",
    widgetType: "app",
    type: "free",
    name: "simplecountdown",
    tagline: "Create recurring tasks in your db with 3 simple steps",
    thumbnail: "/images/apps/recurring-tasks-thumbnail.svg",
    category: "productivity",
    useForBusiness: true,
  },
];
