import { BuildingOffice2Icon, BuildingOfficeIcon, CheckIcon } from "@heroicons/react/20/solid";
import { BriefcaseBusiness, CloudSunRain, SquareMousePointer, Timer, Zap } from "lucide-react";
import { func } from "prop-types";
import { useState } from "react";

export default function FilterSection(props) {
  const { onClick } = props;
  const Categories = [
    {
      title: "All",
    },
    {
      title: "Buttons",
    },
    {
      title: "Counters",
    },
    {
      title: "Weather",
    },
    {
      title: "Productivity",
    },
    {
      title: "Business",
    },
    // {
    //   title: "Templates",
    // },
  ];
  const [selectedCategory, setSelectedCategory] = useState("All");

  const Icons = {
    Buttons: <SquareMousePointer className="tw-h-5 tw-w-5" />,
    Counters: <Timer className="tw-h-5 tw-w-5" />,
    Weather: <CloudSunRain className="tw-h-5 tw-w-5" />,
    Productivity: <Zap className="tw-h-5 tw-w-5" />,
    Business: <BriefcaseBusiness className="tw-h-5 tw-w-5" />,
  };

  const handleClick = (title) => {
    setSelectedCategory(title);
    onClick(title);
  };

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  function isSelected(category) {
    return selectedCategory === category.title;
  }

  return (
    <section id="filter-section" className="py-5 tw-w-full">
      <h2 className="mb-4 h4">Categories</h2>
      <div className="tw-flex tw-flex-wrap tw-gap-3">
        {Categories.map((category) => (
          <button
            key={category.title}
            type="button"
            onClick={() => handleClick(category.title)}
            className={classNames(
              "tw-inline-flex tw-items-center tw-gap-x-2 tw-rounded-md tw-border-solid tw-px-3 tw-py-2 tw-text-md hover:tw-bg-red-100",
              isSelected(category)
                ? "tw-bg-red-50 tw-border-1 tw-text-[#E84300] tw-font-bold tw-border-red-50 hover:tw-border-red-100"
                : "tw-bg-white tw-border-1 tw-border-blue-50 tw-text-gray-700 hover:tw-border-red-100"
            )}
          >
            {isSelected(category) && <CheckIcon className="tw--ml-0.5 tw-h-5 tw-w-5" aria-hidden="true" />}
            {Icons[category.title]}
            {category.title}
          </button>
        ))}
      </div>
    </section>
  );
}
